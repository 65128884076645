<template>
  <section>
    <el-form
      class="el-card is-always-shadow"
      ref="form"
      :model="model"
      :rules="rules"
      :disabled="loading"
      @submit.native.prevent="submit"
    >
      <div class="el-card__header">
        <h1 class="text-center">
          {{ $t('registration') }}
        </h1>
      </div>

      <div class="el-card__body">
        <el-tabs
          v-model="tab"
          class="card-form-tabs"
          stretch
        >
          <el-tab-pane
            name="verification"
            :disabled="disabled === 'verification'"
            lazy
          >
            <template slot="label">
              <fai icon="envelope"/>
              {{ $t('email') }}
            </template>

            <el-form-item
              prop="email"
              :label="$t('email')"
              required
            >
              <el-input
                prefix-icon="el-icon-message"
                v-model="model.email"
              />
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane
            name="registration"
            :disabled="disabled === 'registration'"
            lazy
          >
            <template slot="label">
              <fai icon="school"/>
              {{ $t('institute-info') }}
            </template>

            <el-form-item
              prop="languages"
              :label="$t('languages')"
            >
              <el-select
                class="w-100"
                :placeholder="$t('select')"
                v-model="model.languages"
                multiple
              >
                <div
                  slot="empty"
                  class="empty"
                >
                  <i class="el-icon-loading"></i>
                </div>
                <el-option
                  v-for="lang in languages"
                  :key="lang.id"
                  :value="lang.id"
                  :label="lang.name"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              prop="language"
              :label="$t('default-language')"
            >
              <el-select
                class="w-100"
                :placeholder="$t('select')"
                v-model="model.language"
                :disabled="!model.languages.length"
              >
                <div
                  slot="empty"
                  class="empty"
                >
                  <i class="el-icon-loading"></i>
                </div>
                <el-option
                  v-for="lang in defaultLanguages"
                  :key="lang.id"
                  :value="lang.id"
                  :label="lang.name"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              prop="name"
              :label="$t('institute-name')"
            >
              <el-input
                prefix-icon="el-icon-school"
                v-model="model.name"
              />
            </el-form-item>

            <el-form-item
              prop="username"
              :label="$t('username')"
            >
              <el-input
                prefix-icon="el-icon-school"
                v-model="model.username"
              />
            </el-form-item>

            <el-form-item
              prop="mobile"
              :label="$t('mobile')"
            >
              <mobile-input v-model="model.mobile"/>
            </el-form-item>

            <el-form-item
              prop="email"
              :label="$t('email')"
            >
              <el-input
                prefix-icon="el-icon-message"
                v-model="model.email"
                disabled
              />
            </el-form-item>

            <el-form-item
              prop="password"
              :label="$t('password')"
            >
              <el-input
                prefix-icon="el-icon-key"
                v-model="model.password"
                show-password
              />
            </el-form-item>

            <el-form-item
              prop="confirmedPassword"
              :label="$t('confirm-pass')"
            >
              <el-input
                prefix-icon="el-icon-key"
                v-model="model.confirmedPassword"
                show-password
              />
            </el-form-item>

            <el-form-item
              prop="token"
              :label="$t('code')"
            >
              <el-input
                prefix-icon="el-icon-lock"
                v-model="model.token"
              />
            </el-form-item>

            <el-alert
              effect="dark"
              :closable="false"
              show-icon
            >
              <span class="code-alert">{{ $t('code-alert') }}</span>
            </el-alert>
          </el-tab-pane>

          <el-button
            class="button bg-gd-pm"
            size="big"
            native-type="submit"
            :icon="loading?'el-icon-loading':''"
          >
            <fai
              v-if="!loading"
              icon="paper-plane"
            />
            {{ $t('submit') }}
          </el-button>
        </el-tabs>
      </div>
    </el-form>
  </section>
</template>

<script>
import GET_LANGUAGES from './graphql/ge-languages.graphql';
import VERIFY_EMAIL from './graphql/verify-email.graphql';
import REGISTRATION from './graphql/registration.graphql';
import MobileInput from '@/components/mobile-input';
import omit from 'lodash/omit';
import rules from './rules';

export default {
  components: {
    MobileInput,
  },

  data() {
    return {
      model: {
        name: null,
        username: null,
        mobile: null,
        email: null,
        password: null,
        token: null,
        languages: [],
        language: null,
        confirmedPassword: null,
      },
      languages: null,
      loading: false,
      tab: 'verification',
      disabled: 'registration',
    };
  },

  computed: {
    rules() {
      return rules(this);
    },

    defaultLanguages() {
      if (!Array.isArray(this.languages)) {
        return [];
      }

      return this.languages.filter((lang) => this.model.languages.includes(lang.id));
    },
  },

  apollo: {
    languages: {
      query: GET_LANGUAGES,
      update: (data) => data.misc_Language,
    },
  },

  methods: {
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      // Show spinner
      this.loading = true;

      if (this.tab === 'verification') {
        await this.verify();
      } else {
        await this.register();
      }

      // Hide spinner
      this.loading = false;
    },

    async verify() {
      try {
        await this.$apollo.mutate({
          mutation: VERIFY_EMAIL,
          variables: {
            email: this.model.email,
          },
        });

        this.tab = 'registration';
        this.disabled = 'verification';
      } catch (e) {
        if (e?.graphQLErrors?.[0]?.extensions?.code === 'REG_ALREADY_SENT') {
          this.tab = 'registration';
          this.disabled = 'verification';
        }
      }
    },

    async register() {
      try {
        await this.$apollo.mutate({
          mutation: REGISTRATION,
          variables: omit(this.model, 'confirmedPassword'),
        });

        this.$message({
          duration: 4000,
          type: 'success',
          showClose: true,
          message: this.$t('reg-success'),
        });

        await this.$auth.login(this.model.email, this.model.password);

        setTimeout(() => {
          this.$router.push({
            name: 'app',
          });
        }, 1000);
      } catch (e) {
        //
      }
    },
  },
  created() {
    if (this.$store.state.auth.user) {
      this.$router.push({
        name: 'home',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

.empty {
  text-align: center;
  padding: 5px;
}

h1 {
  margin: 0;
}

.el-card {
  height: calc(100vh - #{ ($header-padding * 2) + $header-height } - 2px);
}

.el-card__body {
  padding: 0;
}

.form-elms {
  padding: 20px;
}

.button {
  width: 100%;
  margin-top: 15px;
}

.error {
  background: #fb5252;
  color: #fff;
  font-size: .9em;
  padding: 10px;
  border-radius: 5px;
  margin-top: 30px;
}

@media all and (min-width: $--md) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - #{ ($header-padding * 2) + $header-height });
    background-image: url("../../../assets/images/background.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

  .el-card {
    width: 450px;
    height: auto;
    margin: 40px 0 30px 0;
  }
}
</style>
