<template>
  <el-input
    @input="$emit('input', mobile)"
    class="input-with-select"
    v-model="model"
  >
    <template #prepend>
      <country-code-select
        :disabled="disabled"
        @input="$emit('input', mobile)"
        v-model="code"
      />
    </template>
  </el-input>
</template>

<script>
import { format, getCountryCallingCode, parse } from 'libphonenumber-js';
import CountryCodeSelect from '@/components/country-code-select';

export default {
  components: { CountryCodeSelect },

  props: {
    value: String,
    disabled: Boolean,
  },

  data() {
    return {
      code: localStorage.getItem('preferred-cCode') || '+880',
      model: null,
    };
  },

  computed: {
    mobile() {
      return format(this.code + this.model, 'E.164');
    },
  },

  watch: {
    value(value) {
      if (this.mobile === value) {
        return;
      }

      this.parseValue(value);
    },
  },

  methods: {
    parseValue(value) {
      if (!value) {
        this.model = null;

        return;
      }

      const parsed = parse(value);

      if (!parsed || !parsed.hasOwnProperty('country')) {
        return;
      }

      this.code = `+${getCountryCallingCode(parsed.country)}`;

      this.model = parsed.phone;
    },
  },
};
</script>
