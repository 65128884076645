import { apolloClient } from '@/bootstrap/apollo-client';
import { isPossibleNumber } from 'libphonenumber-js';
import { i18n } from '@/home/i18n';
import gql from 'graphql-tag';

export function isValidNumber(_, value, callback) {
  if (!value) {
    callback();
  } else if (isPossibleNumber(value)) {
    callback();
  } else {
    callback(i18n.t('validation-mobile-invalid'));
  }
}

export async function isItAvailable(type, value) {
  const result = await apolloClient.query({
    query: gql`query ($type: register_is_available_enum!, $value: String!) {
      register_is_available(payload: {type: $type, value: $value}) {
        available
      }
    }`,
    variables: {
      type,
      value,
    },
  });

  return result.data.register_is_available.available;
}

function availabilityRule(instance, type) {
  return {
    validator(_, value, callback) {
      if (!value) {
        callback();
      } else {
        isItAvailable(type, value)
          .then((yes) => {
            if (!yes) {
              callback(instance.$t('validation-na', { type: instance.$t(type) }));
            } else {
              callback();
            }
          });
      }
    },
    trigger: 'blur',
  };
}

// eslint-disable-next-line func-names
export default function (instance) {
  return {
    name: [
      instance.$requiredRule,
      instance.$max255Rule,
    ],
    username: [
      instance.$max255Rule,
      {
        trigger: 'blur',
        validator(_, value, callback) {
          if (!value) {
            callback();
          } else if (value.indexOf(' ') !== -1) {
            callback(instance.$t('validation-username-no-space'));
          } else if (value.length > 20) {
            callback(instance.$t('validation-max', { length: instance.$n(20) }));
          } else if (value.length < 5) {
            callback(instance.$t('validation-min', { length: instance.$n(5) }));
          } else if (!/^(?=[a-zA-Z0-9.\-_]{5,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(value)) {
            callback(instance.$t('validation-username-invalid'));
          } else {
            callback();
          }
        },
      },
      availabilityRule(instance, 'username'),
    ],
    mobile: [
      instance.$requiredRule,
      {
        validator: isValidNumber,
        trigger: 'blur',
      },
      availabilityRule(instance, 'mobile'),
    ],
    email: [
      instance.$requiredRule,
      {
        type: 'email',
        trigger: 'blur',
        get message() {
          return instance.$t('validation-email-invalid');
        },
      },
      availabilityRule(instance, 'email'),
    ],
    password: [
      instance.$requiredRule,
      {
        trigger: 'blur',
        min: 8,
        get message() {
          return instance.$t('validation-pass-min', { length: instance.$n(8) });
        },
      },
      {
        trigger: 'blur',
        max: 16,
        get message() {
          return instance.$t('validation-pass-max', { length: instance.$n(16) });
        },
      },
    ],
    confirmedPassword: [
      instance.$requiredRule,
      {
        trigger: 'blur',
        validator(_, value, callback) {
          if (value === instance.model.password) {
            callback();
          } else {
            callback(instance.$t('validation-pass-mismatch'));
          }
        },
      },
    ],
    token: instance.$requiredRule,
  };
}
