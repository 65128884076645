<template>
  <el-select
    :filter-method="filter"
    @change="emitInput"
    class="select"
    filterable
    v-model="model"
  >
    <el-option
      :key="country.cc"
      :value="country.c"
      v-for="country in filtered"
    >
      {{ country.f }} {{ country.n }}
    </el-option>
  </el-select>
</template>

<script>
import escapeRegExp from 'lodash/escapeRegExp';

export default {
  props: {
    value: String,
    disabled: Boolean,
  },

  data() {
    return {
      countries: null,

      filtered: null,

      lastKeyword: '',

      model: this.value,
    };
  },

  watch: {
    value(value) {
      if (this.model === value) {
        return;
      }

      this.model = value;
    },
  },

  methods: {
    emitInput() {
      localStorage.setItem('preferred-cCode', this.model);

      this.$emit('input', this.model);
    },

    filter(keyword) {
      let target;

      if (keyword && keyword.indexOf(this.lastKeyword) === 0) {
        target = this.filtered;
      } else {
        target = this.countries;
      }

      this.lastKeyword = keyword;

      const regex = new RegExp(escapeRegExp(keyword), 'gim');

      this.filtered = Object.freeze(
        target.filter(
          (item) => item.n.match(regex) || item.c.match(regex) || item.cc.match(regex),
        ),
      );
    },
  },

  created() {
    import('@/assets/countries.js').then(({ default: data }) => {
      this.countries = Object.freeze(data);
      this.filtered = this.countries;
    });

    this.$nextTick(() => {
      if (localStorage.getItem('preferred-cCode')) {
        this.$emit('input', this.model);
      }
    });
  },
};
</script>

<style scoped>
.select {
  width: 82px;
}
</style>
